@font-face {
  font-family: "montserrat-bd";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "montserrat-md";
  src: url("../fonts/Montserrat/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "montserrat-rg";
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
}

// Brand Colours
$black-800: #222222;
$black-700: #1b1b1b;
$black-400: #252525;
$green-500: #009c5b;
$purple-600: #4f388a;
$red-400: #f53844;

// Neutrals & Shades
$white-100: #ffffff;
$white-200: rgba(
  $color: $white-100,
  $alpha: 0.6,
);
$grey-200: #d9d9d9;
$grey-250: #555555;

$theme-colors: (
  "black-800": $black-800,
  "black-700": $black-700,
  "black-400": $black-400,
  "green-500": $green-500,
  "purple-600": $purple-600,
  "red-400": $red-400,
  "white-100": $white-100,
  "white-200": $white-200,
  "grey-200": $grey-200,
  "grey-250": $grey-250,
);

$font-bd: "montserrat-bd", sans-serif;
$font-md: "montserrat-md", sans-serif;
$font-rg: "montserrat-rg", sans-serif;
