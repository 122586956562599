@import "./variables";

body {
  padding: 0;
  margin: 0;
  font-family: $font-rg;
  font-weight: 400;
  color: $white-100;
  background-color: $black-700;
  &,
  p {
    font-size: 1.7rem;
  }
}

.btn,
.form-control,
a,
span,
button {
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}
.text-green {
  color: $green-500 !important;
}
.object-contain{
  object-fit: contain !important;
}