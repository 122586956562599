@import './variables';
.btn {
  font-weight: bold;
  font-family: $font-rg;
  border-radius: .2rem;
}
.btn-primary {
  background: $green-500;
  color: $white-100;
  &:hover {
    color: $white-100;
  }
}

.btn-lg {
  min-height: 4rem;
  width: 100%;
  font-size: 1.8rem;
  padding: 1rem;
}
.btn-md {
  padding: 0.7rem;
  min-width: 12.2rem;
  font-size: 1.5rem;
}
.btn-sm {
  min-height: 2rem;
}



