@import "assets/scss/variables";

.title {
  font-size: 5.6rem;
  font-family: $font-bd;
  margin-bottom: 4rem;
  @media (max-width: 1400px) {
    font-size: 4rem;
  }
}
.hero {
  padding-top: 8vh;
  min-height: 100vh;
  background: $black-400;
  position: relative;
  .hero-contact {
    transform: rotate(-90deg);
    height: 5rem;
    width: 0;
    margin-top: 6rem;
    margin-left: -13rem;
  }
  .banner-img {
    position: relative;
    .top-overlay {
      position: absolute;
      top: -5.6rem;
      right: -2.2rem;
      z-index: 1;
      width: 14rem;
      height: 13rem;
      background: rgba($color: $green-500, $alpha: 0.4);
    }
    .bottom-overlay {
      position: absolute;
      bottom: 2rem;
      left: -15rem;
      z-index: 1;
      width: 35rem;
      height: 10rem;
      background: rgba($color: $green-500, $alpha: 0.4);
    }
    // img {
    //   width: 100%;
    //   height: 72vh;
    // }
  }
}
