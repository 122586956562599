@import "assets/scss/variables";
.contact-wrapper {
  &.fixed {
    position: fixed;
    right: 3rem;
    bottom: 0;
  }
  &.home-page {
    @media (max-width: 992px) {
      display: none;
    }
  }
  .line {
    height: 13.5rem;
    opacity: 0.5;
    border: 1px solid #ffffff;
    width: 0;
  }
  .contact-links-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    .contact-link {
      width: 4rem;
      height: 4rem;
      border: 2px solid $green-500;
      border-radius: 1rem;
      color: $white-100;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.rotate {
      .contact-link {
        transform: rotate(90deg);
      }
    }
  }
}
