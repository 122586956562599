@import "./variables";
html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  @media (max-width: 992px) {
    font-size: 50%;
  }
}
a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
  }
}
.container {
  padding-inline: var(--bs-gutter-x, 2rem);
  // padding-inline: 1.5rem;
  width: 100%;
  margin-inline: auto;
  @media (max-width: 1000px) {
    padding-inline: 1.5rem;
  }
  // @media (min-width: 1400px) {
  //   max-width: 140rem;
  // }
  @media (min-width: 1700px) {
    max-width: 150rem;
  }
  @media (min-width: 1800px) {
    max-width: 160rem;
  }
}

.mb-5,
.my-5 {
  margin-bottom: 4rem !important;
}
.mt-5,
.my-5 {
  margin-top: 4rem !important;
}

.pt-5,
.py-5 {
  padding-bottom: 4rem !important;
}

.pb-5 {
  padding-bottom: 4rem !important;
}

.py-5 {
  padding-top: 4rem !important;
}

.font-bold {
  font-family: $font-bd;
  font-weight: 700;
}
.font-medium {
  font-family: $font-md;
  font-weight: 500;
}

.text-base {
  font-size: 0.875rem;
}

.text-xs {
  font-size: 0.625rem;
}

.text-sm {
  font-size: 0.75rem;
}

.text-lg {
  font-size: 1rem;
}

.text-xl {
  font-size: 1.125rem;
}
.text-xxl {
  font-size: 1.5rem;
}
.text-2xl {
  font-size: 2rem;
}
