@import "assets/scss/variables";
.portfolio-page {
  .portfolio-box {
    height: 48rem;
    position: relative;
    margin-bottom: 13rem;
    cursor: pointer;
    overflow: hidden;
    border-radius: 0.7rem;
    &::after {
      background-color: rgba(170, 173, 188, 0.46);
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      bottom: 0;
      top: 0;
      left: -100%;
      right: 0;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover {
      &::after {
        -webkit-transition: all 1s, -webkit-transform 1s;
        transition: all 1s, transform 1s;
        left: -45%;
        opacity: 1;
      }
    }

    img {
      width: 55%;
      height: 48rem;
      object-fit: cover;
      object-position: top;
    }
    .details-wrap {
      width: 45%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      padding: 2rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .name {
        font-size: 2rem;
        font-family: $font-bd;
      }
      .overview {
        font-size: 1.8rem;
        .title {
          font-size: 1.4rem;
          color: $green-500;
          margin-bottom: 0;
        }
      }
    }
    &:nth-child(odd) {
      &::after {
        left: 100%;
      }
      &:hover {
        &::after {
          left: 45%;
        }
      }
      img {
        margin-left: auto;
      }
      .details-wrap {
        left: 0;
        right: auto;
      }
    }
    @media (max-width: 1000px) {
      img,
      .details-wrap {
        width: 100%;
        background-color: rgba(36, 36, 44, 0.75);
      }
    }
  }
  .more-to-come {
    font-family: $font-bd;
    font-size: 1.9rem;
  }
}
