@import "assets/scss/variables";
.about-page {
  margin-top: 8vh;
  header {
    margin-top: 10vh;
  }
  h4 {
    font-size: 2.5rem;
    font-family: $font-bd;
  }
  h1 {
    font-size: 5rem;
    font-family: $font-bd;
  }
  .desc {
    font-size: 2rem;
    opacity: .8;
  }
  .top-links {
    a {
      display: flex;
      text-decoration: underline;
      color: $grey-200;
      align-items: center;
      margin-right: 5rem;
      .text-green {
        color: $green-500;
      }
    }
  }

  .stack-grid {
    .item {
      background: #292929;
      border-radius: 0.8rem;
    }
    .item1 {
      grid-area: one;
    }
    .item2 {
      grid-area: two;
    }
    .item3 {
      grid-area: three;
    }
    .item4 {
      grid-area: four;
    }
    .item5 {
      grid-area: five;
    }
    .item6 {
      grid-area: six;
    }
    display: grid;
    grid-template-areas:
      "one four four five"
      "one two three six ";
    grid-gap: 0.4rem;
    padding: 0.4rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .spare-time-box {
    .spare-time-imgs {
      display: grid;
      grid-template-areas:
        "three one"
        "three two";
      grid-gap: 1rem;
      .sm-im1 {
        grid-area: one;
      }
      .sm-img2 {
        grid-area: two;
      }
      .big-img {
        grid-area: three;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    p {
      font-size: 1.8rem;
    }
  }
}
